window.jQuery = window.$ = require('jquery');

window.Modernizr = require('./modernizr');

window.Popper = require('@popperjs/core');

window.bootstrap = require('bootstrap');
require('slick-carousel');
require('jquery.easing');
require('./scrolling-nav');


require("./assets/css/animate.css");
require("./assets/css/magnific-popup.css");
require("./assets/css/slick.css");
require("./assets/css/LineIcons.css");
require("./assets/css/font-awesome.min.css");
require("./assets/css/bootstrap.min.css");
require("./assets/css/default.css");
require("./assets/css/style.css");


// require("./assets/js/vendor/jquery-1.12.4.min.js");
// require("./assets/js/vendor/modernizr-3.7.1.min.js");
// require("./assets/js/popper.min.js");
// require("./assets/js/bootstrap.min.js");
require("./assets/js/plugins.js");
// require("./assets/js/slick.min.js");
require("./assets/js/ajax-contact.js");
require("./assets/js/waypoints.min.js");
require("./assets/js/jquery.counterup.min.js");
require("./assets/js/jquery.magnific-popup.min.js");
// require("./assets/js/jquery.easing.min.js");
// require("./assets/js/scrolling-nav.js");
window.WOW = require("./assets/js/wow.min.js").WOW;
require("./assets/js/particles.min.js");
require("./assets/js/main.js");
